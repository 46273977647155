// ** Admin user ability
export const adminAbility = [
  {
    action: "read",
    subject: "home"
  },
  {
    action: "edit",
    subject: "reservations:channel-management"
  },
  {
    action: "view",
    subject: "reservations:analytics"
  },
  {
    action: "edit",
    subject: "settings:groups"
  },
  {
    action: "edit",
    subject: "settings:brands"
  },
  {
    action: "view",
    subject: "settings:account"
  },
  {
    action: "filter",
    subject: "venues:selection"
  },
  {
    action: "edit",
    subject: "settings:venues"
  },
  {
    action: "edit",
    subject: "settings:notifications"
  },
  {
    action: "edit",
    subject: "listing:connection-management"
  },
  {
    action: "view",
    subject: "listing:analytics"
  },
  {
    action: "edit",
    subject: "reviews:comments-management"
  },
  {
    action: "view",
    subject: "reviews:analytics"
  },
  {
    action: "view",
    subject: "resbook:dashboard"
  },
  {
    action: "view",
    subject: "resbook:configuration"
  }
  // {
  //   action: 'edit',
  //   subject: 'settings:widgets'
  // }
]
